<template>
	<div class="contaner-fluid">
		<div class="row"> 
			<div class="col-xl-12">
				<div class="alert inpage-alert alert-custom alert-light-primary mb-5 p-5" role="alert">
					<div class="alert-text">
						<h3 class="alert-heading">
							Dossier Numéro <b>{{ this.num_dossier }}</b> soumit à l'administration !
						</h3>
						<p class="font-size-h5">
							Votre candidature a été soumise à l'admistration pour étude
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Layout from "@/view/layout/Layout.vue";
export default {
	metaInfo: { title: "Tableau de bord" },
	layout: Layout,
	props: {
		num_dossier: String,
	},
};
</script>
