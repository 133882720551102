<template>
	<div class="container">
		<div class="row">
			<div class="col-8 offset-2">
				<div class="card card-custom">
					<div class="card-header py-3">
						<div class="card-title align-items-start flex-column">
							<h3 class="card-label font-weight-bolder text-dark">
								Changer le mot de passe
							</h3>
							<span class="text-muted font-weight-bold font-size-sm mt-1">Changer le mot de passe de votre compte</span>
						</div>
					</div>
					<div class="card-body">
						<form class="form" novalidate="novalidate" id="change_form">
							<div class="form-group row">
								<label class="col-xl-3 col-lg-3 col-form-label text-alert">
									Mot de passe actuel {{ form.current_password }}
								</label>
								<div class="col-lg-9 col-xl-6">
									<input
										type="password"
										class="form-control form-control-lg form-control-solid mb-2"
										v-model="form.current_password"
										placeholder="Mot de passe actuel"
										name="current_password"
									/>
								</div>
							</div>
							<div class="form-group row">
								<label class="col-xl-3 col-lg-3 col-form-label text-alert">
									Nouveau mot de passe
								</label>
								<div class="col-lg-9 col-xl-6">
									<input
										type="password"
										class="form-control form-control-lg form-control-solid"
										v-model="form.password"
										placeholder="Nouveau mot de passe"
										name="password"
									/>
								</div>
							</div>
							<div class="form-group row">
								<label class="col-xl-3 col-lg-3 col-form-label text-alert">
									Vérifier le mot de passe
								</label>
								<div class="col-lg-9 col-xl-6">
									<input
										type="password"
										class="form-control form-control-lg form-control-solid"
										v-model="form.password_confirmation"
										placeholder="Vérifier le mot de passe"
										name="password_confirmation"
									/>
								</div>
							</div>
						</form>
					</div>
					<div class="card-footer">
						<button class="btn btn-primary mr-2 float-right" ref="change_submit">
							Enregistrer
						</button>
						<button class="btn btn-secondary px-10" @click="$inertia.get('/admission/dashboard')">
							Annuler
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Layout from "@/view/layout/Layout.vue";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import Swal from "sweetalert2";

export default {
	metaInfo: { title: "Tableau de bord" },
	layout: Layout,
	data() {
		return {
			form: {
				password: "",
				current_password: "",
				password_confirmation: "",
			},
		};
	},
	mounted() {
		const change_form = KTUtil.getById("change_form");
		const submitButton = this.$refs["change_submit"];

		this.fv = formValidation(change_form, {
			fields: {
				current_password: {
					validators: {
						notEmpty: {
							message: "Mot de passe actuel obligatoire",
						},
					},
				},	
				password: {
					validators: {
						notEmpty: {
							message: "Mot de passe obligatoire",
						},
						stringLength: {
							min: 6,
							message: "Le mot de passe doit avoire au moins 6 caractères",
						},
					},
				},				
				password_confirmation: {
					validators: {
						identical: {
							compare: function () {
								return change_form.querySelector('[name="password"]').value;
							},
							message:"Le mot de passe et sa confirmation ne sont pas les mêmes",
						},
					},
				},
			},
			plugins: {
				trigger: new Trigger(),
				submitButton: new SubmitButton(),
				bootstrap: new Bootstrap(),
			},
		});
		
		submitButton.addEventListener("click", () => {
			this.fv.validate().then(status => {
				if (status === "Valid") {
					let data = {};
					data["current_password"] = this.form.current_password;
					data["password"] = this.form.password;
					data["password_confirmation"] = this.form.password_confirmation;

					submitButton.classList.add(
						"spinner",
						"spinner-light",
						"spinner-right"
					);

					this.axios
					.post("/admission/profile/change-password", data)
					.then(resp => {
						if (resp.data.validation) {
							Swal.fire({
								title: "",
								text: resp.data.message,
								icon: "success",
								confirmButtonClass: "btn btn-secondary",
								heightAuto: false,
							}).then(function () {
								this.$inertia.get("/admission/dashboard");
							});
						} else {
							Swal.fire({
								title: "",
								text: resp.data.message,
								icon: "warning",
								confirmButtonClass: "btn btn-secondary",
								heightAuto: false,
							});
						}
					});

					submitButton.classList.remove(
						"spinner",
						"spinner-light",
						"spinner-right"
					);
				}
			});
		});
	},
};
</script>
