<template>
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <template v-if="form.status === 1">
                    <div class="alert alert-custom alert-primary-dark-blue  mb-5 p-5" role="alert">
                        <div class="alert-text">
                            <h3 class="alert-heading">
                                <strong v-if="form.register === 'I'">Dossier de candidature</strong>
                                <strong v-else>Dossier de transfert</strong>
                            </h3>
                            <p class="font-size-h5">Veuillez compléter le formulaire ci-dessous et assurez-vous que
                                toutes les informations sont correctes avant de passer à l’étape suivante</p>
                        </div>
                    </div>
                </template>
                <template v-if="form.status === 4">
                    <div class="alert alert-custom alert-light-warning mb-5 p-5" role="alert">
                        <div class="alert-text">
                            <h3 class="alert-heading"><strong>Dossier rejeté</strong></h3>
                            <p class="font-size-h5">
                                Votre dossier d'inscription N° : {{ form.num_dossier }} a été rejeté<br>
                                Veuillez compléter le formulaire de candidature.
                            </p>
                        </div>
                    </div>
                </template>
                <div class="card card-custom">
                    <div class="card-body p-0">
                        <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first"
                            data-wizard-clickable="true">
                            <div class="wizard-nav border-bottom">
                                <div class="wizard-steps p-3 p-lg-5">
                                    <div class="wizard-step" data-wizard-type="step" data-wizard-state="current">
                                        <div class="wizard-label">
                                            <h3 class="wizard-title">1.Fiche d’inscription</h3>
                                        </div>
                                        <i class="wizard-arrow flaticon2-next"></i>
                                    </div>
                                    <div class="wizard-step" data-wizard-type="step">
                                        <div class="wizard-label">
                                            <h3 class="wizard-title">2.Pièces jointes</h3>
                                        </div>
                                        <i class="wizard-arrow flaticon2-next"></i>
                                    </div>
                                    <div class="wizard-step" data-wizard-type="step">
                                        <div class="wizard-label">
                                            <template v-if="form.status === 1">
                                                <h3 class="wizard-title">3.Validation</h3>
                                            </template>
                                            <template v-if="form.status === 4">
                                                <h3 class="wizard-title">Motifs de rejets</h3>
                                            </template>
                                        </div>
                                        <i class="wizard-arrow last flaticon2-next"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
                                <div class="col-xl-12 col-xxl-12">
                                    <form class="form" id="kt_form">
                                        <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                            <fieldset class="col-xl-12 margin-bottom"
                                                style="border: 1px solid var(--dark-blue-color) !important;color:var(--dark-blue-color) !important"
                                                :disabled="!canEdit">
                                                <legend style="width:unset">
                                                    <h3>Informations personnelles</h3>
                                                </legend>
                                                <div class="row">
                                                    <div class="col-xl-6">
                                                        <div class="form-group">
                                                            <label class="label125">Nom</label>
                                                            <input type="text" class="form-control form-control-solid "
                                                                @blur="saveData('nom', form.nom)" placeholder="Nom"
                                                                v-model="form.nom" name="nom" />
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-6">
                                                        <div class="form-group">
                                                            <label class="label125">Prénom</label>
                                                            <input type="text" class="form-control form-control-solid "
                                                                @blur="saveData('prenom', form.prenom)"
                                                                v-model="form.prenom" placeholder="Prénom"
                                                                name="prenom" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xl-6">
                                                        <div class="form-group">
                                                            <label class="label125">Numéro pièce identité</label>
                                                            <input
                                                                type="text" 
                                                                class="form-control form-control-solid form-control-sm" 
                                                                placeholder="Numéro pièce identité" 
                                                                v-model="form.identity"
                                                                @blur="saveData('identity', form.identity)"
                                                                name="identity"
                                                            >
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xl-3">
                                                        <div class="form-group">
                                                            <label class="label125">Sexe</label>
                                                            <select class="form-control form-control-solid "
                                                                v-model="form.sexe"
                                                                v-on:change="saveData('sexe', form.sexe)" name="sexe">
                                                                <option value=""></option>
                                                                <option value="Masculin">Masculin</option>
                                                                <option value="Féminin">Féminin</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-3">
                                                        <div class="form-group">
                                                            <label class="label125">Situation de famille</label>
                                                            <select class="form-control form-control-solid "
                                                                v-model="form.family_situation"
                                                                v-on:change="saveData('family_situation', form.family_situation)"
                                                                name="situation_famille">
                                                                <option value=""></option>
                                                                <option value="Célibataire"> Célibataire </option>
                                                                <option value="Marié(e)"> Marié(e) </option>
                                                                <option value="Divorcé(e)"> Divorcé(e) </option>
                                                                <option value="Veuf(ve)"> Veuf(ve) </option>
                                                            </select>

                                                        </div>
                                                    </div>
                                                    <div class="col-xl-6">
                                                        <div class="form-group">
                                                            <label class="label125">Nationalité</label>
                                                            <select class="form-control form-control-solid "
                                                                v-model="form.nationality"
                                                                v-on:change="saveData('nationality', form.nationality)"
                                                                name="nationalite">
                                                                <option value=""></option>
                                                                <option v-for="country in this.pays"
                                                                    v-bind:value="country.id" :key="country.id">
                                                                    {{ country.libelle }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xl-6">
                                                        <div class="form-group">
                                                            <label class="label125">Date de naissance</label>
                                                            <date-picker
                                                                class=" w-100"
                                                                v-model="form.birth_date"
                                                                format="DD-MM-YYYY"
                                                                value-type="YYYY-MM-DD"
                                                                :editable=false
                                                                :disabled-date="disabledRange"
                                                                :default-value="defaultValue"
                                                                input-class="form-control form-control-solid"
                                                                @input="saveData('birth_date', form.birth_date)">
                                                            </date-picker>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-3">
                                                        <div class="form-group">
                                                            <label class="label125">Pays de naissance</label>
                                                            <select class="form-control form-control-solid "
                                                                v-model="form.birth_place"
                                                                v-on:change="saveData('birth_place', form.birth_place)"
                                                                name="lieu_naissance">
                                                                <option value=""></option>
                                                                <option v-for="country in this.pays"
                                                                    v-bind:value="country.id" :key="country.id">
                                                                    {{ country.libelle }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-3">
                                                        <div class="form-group">
                                                            <label class="label125">Ville de naissance</label>
                                                            <input type="text" class="form-control form-control-solid "
                                                                v-model="form.birth_city"
                                                                placeholder="Ville de naissance"
                                                                @blur="saveData('birth_city', form.birth_city)">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xl-6">
                                                        <div class="form-group">
                                                            <label class="label125">Adresse</label>
                                                            <input type="text" class="form-control form-control-solid "
                                                                v-model="form.address" placeholder="Adresse"
                                                                @blur="saveData('address', form.address)"
                                                                name="adresse" />
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-6">
                                                        <div class="form-group">
                                                            <label class="label125">E-mail</label>
                                                            <input type="text" class="form-control form-control-solid "
                                                                v-model="form.email" placeholder="E-mail"
                                                                @blur="saveData('email', form.email)" name="email" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xl-6">
                                                        <div class="form-group">
                                                            <label class="label125">Téléphone</label>
                                                            <vue-tel-input class="form-control form-control-solid "
                                                                v-bind="bindProps" v-model="form.phone"
                                                                :inputOptions="optionsPhone"
                                                                :autoDefaultCountry="autoDefaultCountryPhone"
                                                                @blur="saveData('phone', form.phone)"
                                                                @validate="validePhone"></vue-tel-input>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-6">
                                                        <div class="form-group">
                                                            <label class="label125">Fax</label><br>
                                                            <vue-tel-input class="form-control form-control-solid "
                                                                v-model="form.fax" v-bind="bindProps"
                                                                :inputOptions="optionsFax"
                                                                @blur="saveData('fax', form.fax)"
                                                                @validate="valideFax"></vue-tel-input>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                            <fieldset class="col-xl-12 margin-bottom"
                                                style="border: 1px solid var(--dark-blue-color) !important;color:var(--dark-blue-color) !important"
                                                :disabled="!canEdit">
                                                <legend style="width:unset">
                                                    <h3>Admission pour : </h3>
                                                </legend>
                                                <div class="row">
                                                    <div class="col-xl-4">
                                                        <div class="form-group">
                                                            <label class="label125">Premier choix</label>
                                                            <select class="form-control form-control-solid "
                                                                v-model="form.admission1"
                                                                @change="handleChangeAdmission" name="premier_choix">
                                                                <option value=""></option>
                                                                <option v-for="adm in this.admissions"
                                                                    v-bind:value="adm" :key="adm">
                                                                    {{ adm }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-4">
                                                        <div class="form-group">
                                                            <label class="label125">Deuxième choix</label>
                                                            <select class="form-control form-control-solid "
                                                                v-model="form.admission2"
                                                                @change="handleChangeAdmission" name="deuxieme_choix">
                                                                <option value=""></option>
                                                                <option v-for="adm in this.admissions"
                                                                    v-bind:value="adm" :key="adm">
                                                                    {{ adm }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-4">
                                                        <div class="form-group">
                                                            <label class="label125">Troisième choix</label>
                                                            <select class="form-control form-control-solid "
                                                                v-model="form.admission3"
                                                                @change="handleChangeAdmission" name="troisieme_choix">
                                                                <option value=""></option>
                                                                <option v-for="adm in this.admissions"
                                                                    v-bind:value="adm" :key="adm">
                                                                    {{ adm }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                            <fieldset class="col-xl-12 margin-bottom"
                                                style="border: 1px solid var(--dark-blue-color) !important;color:var(--dark-blue-color) !important"
                                                :disabled="!canEdit">
                                                <legend style="width:unset">
                                                    <h3>Parcours académique</h3>
                                                </legend>
                                                <div class="row">
                                                    <div class="col-xl-4">
                                                        <div class="form-group">
                                                            <label class="label125">Baccalauréat Série</label>
                                                            <input type="text" class="form-control form-control-solid "
                                                                v-model="form.bac_series"
                                                                placeholder="Baccalauréat Série"
                                                                @blur="saveData('bac_series', form.bac_series)"
                                                                name="bac_serie" />
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-4">
                                                        <div class="form-group">
                                                            <label class="label125">Année d’obtention</label>
                                                            <select placeholder="Sélectionnez un année"
                                                                class="form-control form-control-solid "
                                                                v-model="form.bac_year"
                                                                v-on:change="saveData('bac_year', form.bac_year)"
                                                                name="bac_year">
                                                                <option value=""></option>
                                                                <option v-for="year in getCurrentYear()"
                                                                    v-bind:value="year" :key="year">
                                                                    {{ year }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-4">
                                                        <div class="form-group">
                                                            <label class="label125">Mention</label>
                                                            <input type="text" class="form-control form-control-solid "
                                                                v-model="form.bac_mention" placeholder="Très bien, Assez-bien"
                                                                @blur="saveData('bac_mention', form.bac_mention)"
                                                                name="bac_mention" maxlength="20" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xl-12">
                                                        <div class="form-group">
                                                            <label class="label125">Lycée ou Collège</label>
                                                            <input type="text" class="form-control form-control-solid "
                                                                v-model="form.bac_school" placeholder="Lycée ou Collège"
                                                                @blur="saveData('bac_school', form.bac_school)"
                                                                name="bac_school" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <template v-if="form.register === 'T'">
                                                    <div class="row">
                                                        <div class="col-xl-12">
                                                            <hr />
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-xl-4">
                                                            <div class="form-group mb-2">
                                                                <label class="label125">Faculté de Médecine
                                                                    fréquentée</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-1">
                                                            <div class="form-group mb-2">
                                                                <label class="label125">Année</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-5">
                                                            <div class="form-group mb-2">
                                                                <label class="label125">Raisons pour lesquelles vous
                                                                    avez quitté</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-2"></div>
                                                    </div>
                                                    <div class="row" v-for="(input, index) in form.medical_universities"
                                                        :key="index">
                                                        <div class="col-xl-4">
                                                            <div class="form-group mb-2">
                                                                <input type="text"
                                                                    class="form-control form-control-solid "
                                                                    ref="university"
                                                                    placeholder="Faculté de Médecine fréquentée"
                                                                    @blur="handleBlurUniversity"
                                                                    v-bind:value="input.faculty" />
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-1">
                                                            <div class="form-group mb-2">
                                                                <select class="form-control form-control-solid "
                                                                    ref="university_year"
                                                                    v-on:change="handleBlurUniversity"
                                                                    v-bind:value="input.year">
                                                                    <option value=""></option>
                                                                    <option v-for="year in getCurrentYear()"
                                                                        v-bind:value="year" :key="year">
                                                                        {{ year }}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-5">
                                                            <div class="form-group mb-2">
                                                                <input type="text"
                                                                    class="form-control form-control-solid "
                                                                    ref="university_raisons"
                                                                    placeholder="Raison pour lesquelles vous avez quitté"
                                                                    @blur="handleBlurUniversity"
                                                                    v-bind:value="input.reason" />
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-2" v-if="index > 0">
                                                            <div class="form-group  mb-2">
                                                                <a href="javascript:;"
                                                                    class="btn btn-sm font-weight-bolder "
                                                                    v-on:click="deleteInput(index, form.medical_universities, 'medical_universities')"><i
                                                                        class="flaticon-cancel icon-2x "></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <div class="col-lg-4">
                                                            <a href="javascript:;" v-on:click="addInputMedical()"
                                                                class="btn btn-sm font-weight-bolder btn-link-primary">
                                                                <i class="la la-plus"></i>Ajouter</a>
                                                        </div>
                                                    </div>
                                                </template>
                                                <div class="row">
                                                    <div class="col-xl-12">
                                                        <hr />
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-xl-8">
                                                        <div class="form-group mb-2">
                                                            <label class="label125">Autre Faculté, Ecole ou Institut
                                                                fréquenté</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-2">
                                                        <div class="form-group mb-2">
                                                            <label class="label125">Période </label><span class="text-muted">(Ex 2019-2022)</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-2"></div>
                                                </div>
                                                <div class="row" v-for="(input, index) in form.other_universities" :key="'ou_' + index">
                                                    <div class="col-xl-8">
                                                        <div class="form-group mb-2">
                                                            <input type="text" class="form-control form-control-solid "
                                                                ref="university_other"
                                                                placeholder="Autre Faculté, Ecole ou Institut fréquenté"
                                                                @blur="handleBlurUniversityOther"
                                                                v-bind:value="input.faculty" />
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-2">
                                                        <div class="form-group mb-2">
                                                            <input 
                                                                type="text" 
                                                                v-mask="'####-####'"
                                                                class="form-control form-control-solid "
                                                                ref="university_other_year" 
                                                                placeholder="____-____"
                                                                @blur="handleBlurUniversityOther"
                                                                v-bind:value="input.period"
                                                                v-bind:id="'university_other_year_' + index" />
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-2" v-if="index > 0">
                                                        <a href="javascript:;"
                                                            v-on:click="deleteInput(index, form.other_universities, 'other_universities')"
                                                            class="btn btn-sm font-weight-bolder ">
                                                            <i class="flaticon-cancel icon-2x"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <div class="col-lg-4">
                                                        <a href="javascript:;" v-on:click="addInputInstitut()"
                                                            class="btn btn-sm font-weight-bolder btn-link-primary">
                                                            <i class="la la-plus"></i>Ajouter</a>
                                                    </div>
                                                </div>
                                            </fieldset>
                                            <fieldset class="col-xl-12 margin-bottom"
                                                style="border: 1px solid var(--dark-blue-color) !important;color:var(--dark-blue-color) !important"
                                                :disabled="!canEdit">
                                                <legend style="width:unset">
                                                    <h3>Languages et Autres</h3>
                                                </legend>
                                                <div class="row">
                                                    <div class="col-xl-6">
                                                        <div class="form-group">
                                                            <label class="label125">Langue nationale</label>
                                                            <select class="form-control form-control-solid "
                                                                v-model="form.national_language"
                                                                v-on:change="saveData('national_language', form.national_language)"
                                                                name="national_language">
                                                                <option value=""></option>
                                                                <option v-for="(langue, index) in langues"
                                                                    v-bind:value="langue.id" :key="'ln' + index">
                                                                    {{ langue.libelle }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xl-12">
                                                        <hr />
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xl-6">
                                                        <label class="label125">Langue parlée</label>
                                                    </div>
                                                </div>
                                                <div class="row" v-for="(input, index) in form.spoken_languages"
                                                    :key="'sl_' + index">
                                                    <div class="col-xl-6">
                                                        <div class="form-group mb-2">
                                                            <select class="form-control form-control-solid "
                                                                ref="languages_talk" placeholder="Langue parlée"
                                                                @blur="handleBlurLanguagesTalk" v-bind:value="input">
                                                                <option value=""></option>
                                                                <option v-for="(langue, idx) in langues"
                                                                    v-bind:value="langue.id" :key="'sl_' + idx">
                                                                    {{ langue.libelle }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-2" v-if="index > 0">
                                                        <div class="form-group  mb-2">
                                                            <a href="javascript:;"
                                                                class="btn btn-sm font-weight-bolder "
                                                                v-on:click="deleteInput(index, form.spoken_languages, 'spoken_languages')"><i
                                                                    class="flaticon-cancel icon-2x "></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <div class="col-lg-4">
                                                        <a href="javascript:;" v-on:click="addInputLanguage()"
                                                            class="btn btn-sm font-weight-bolder btn-link-primary">
                                                            <i class="la la-plus"></i>Ajouter</a>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xl-5">
                                                        <div class="form-group">
                                                            <label class="label125">Avez-vous des problèmes de santé
                                                                particuliers</label>
                                                            <div class="radio-inline">
                                                                <label
                                                                    class="radio radio-outline radio-primary radio-lg">
                                                                    <input type="radio" name="health_problems"
                                                                        v-model="form.health_problems" value=1
                                                                        v-on:change="checkHealth('health_problems', form.health_problems)"><span></span>Oui
                                                                </label>
                                                                <label
                                                                    class="radio radio-outline radio-primary radio-lg">
                                                                    <input type="radio" name="health_problems"
                                                                        v-model="form.health_problems" value=0
                                                                        v-on:change="checkHealth('health_problems', form.health_problems)"><span></span>Non
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-6">
                                                        <div class="form-group">
                                                            <label class="label125">Si oui, êtes-vous sous surveillance
                                                                médicale</label>
                                                            <div class="radio-inline">
                                                                <label
                                                                    class="radio radio-outline radio-primary radio-lg">
                                                                    <input type="radio" name="medical_supervision"
                                                                        v-model="form.medical_supervision" value=1
                                                                        :disabled='isDisabled'
                                                                        v-on:change="saveData('medical_supervision', form.medical_supervision)">
                                                                    <span></span>Oui
                                                                </label>
                                                                <label
                                                                    class="radio radio-outline radio-primary radio-lg">
                                                                    <input type="radio" name="medical_supervision"
                                                                        v-model="form.medical_supervision" value=0
                                                                        :disabled='isDisabled'
                                                                        v-on:change="saveData('medical_supervision', form.medical_supervision)">
                                                                    <span></span>Non
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xl-12">
                                                        <div class="form-group">
                                                            <label class="label125">Veuillez choisir la date de
                                                                démarrage souhaitée</label>
                                                            <div class="radio-inline">
                                                                <label v-for="(input, index) in sessions"
                                                                    class="radio radio-outline radio-primary radio-lg"
                                                                    :key="'ss_' + index">
                                                                    <input type="radio" name="session"
                                                                        v-model="form.session"
                                                                        v-bind:value="input.year + '+' + input.month"
                                                                        v-on:change="saveData('session', form.session)">
                                                                    <span></span>{{ input.month + ' ' + input.year }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <div class="pb-5" data-wizard-type="step-content">
                                            <div class="alert alert-custom alert-notice alert-light-primary fade show"
                                                role="alert">
                                                <div class="alert-icon"><i class="flaticon-warning"></i></div>
                                                <div class="alert-text"><strong>Important ! :</strong> Veillez à ce que
                                                    les copies des pièces que vous soumettez soient lisibles.<br />
                                                    La taille des pièces est limitée à 6 Mo par pièce.<br />
                                                    Les formats acceptés sont (JPG, JPEG, PNG, PDF).</div>

                                            </div>
                                            <fieldset class="col-xl-12 pl-0 pr-0  margin-bottom"
                                                style="border: none !important" :disabled="!canEdit">
                                                <div v-for="(input, index) in attachsList" :key="'al_' + index">
                                                    <div v-if="input.code!=='payp'">
                                                    <div  class="card p-0 upldfl"
                                                        v-bind:class="[index > 0 ? 'mt-8 mt-lg-8' : '']"
                                                        v-bind:data="input.name" style="background-color: #F3F6F9;"
                                                        v-bind:style="input.name != '' ? 'border: solid 1px #1BC5BD' : 'border: 1px solid rgba(43, 51, 76,.3) !important;'">
                                                        <div class="card-body pt-5 pb-5 d-flex align-items-center justify-content-between">
                                                            <div class="mr-4 ">
                                                                <h4 class="text-dark mb-0">{{ input.libelle }} </h4>
                                                                <!--<p class="text-dark-50 font-size-lg">{{ input.description }}</p>-->
                                                            </div>
                                                            <div v-if="input.name !== ''">
                                                                <a href="#"
                                                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                                                    {{ input.name }}
                                                                </a>
                                                                <button
                                                                    class="btn font-weight-bolder font-size-lg btn-danger btn-sm ml-4"
                                                                    :data-id="input.id"
                                                                    @click="deleteFile">Supprimer&nbsp;<i
                                                                        class="flaticon2-rubbish-bin icon-md"></i>
                                                                </button>
                                                            </div>
                                                            <div v-else>
                                                                <button
                                                                    class="btn font-weight-bolder font-size-lg btn-primary btn-sm"
                                                                    :data-id="input.id" :data-name="input.libelle"
                                                                    @click="showModal">Ajouter&nbsp;<i
                                                                        class="flaticon-upload icon-md"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="input.name === ''" class="text-danger upldfl-error">Ce document est obligatoire</div>
                                                </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <div class="pb-5" data-wizard-type="step-content">
                                            <template v-if="form.status === 1">

                                                <div class="notice
      d-flex
      bg-light-primary
      rounded
      border-primary
      border
      border-dashed
      rounded-3
     p-6"><!----><div class="d-flex flex-stack flex-grow-1"><div class="fw-semibold undefined"><h4 class="text-gray-900 fw-bold">Frais de dossier - 100 000 FCFA </h4><div class="fs-6 text-gray-700 pe-7">Pour soumettre votre dossier de candidature à l'École de médecine Saint Christopher Iba Mar Diop, veuillez noter qu'un frais administratif de 1000 FCFA est requis. Ce montant couvrira les frais d'évaluation de votre dossier. Veuillez suivre les instructions fournies dans le formulaire de candidature en ligne pour effectuer ce paiement. Merci pour votre coopération..
        <p>
            <h5 class="text-gray-900 font-weight-bolder">Relevé d'Identité Bancaire :</h5>
            Banque : <span class="text-gray-900 font-weight-bolder">SN012</span><br/>
            N° de compte : <span class="text-gray-900 font-weight-bolder">SN0120120303615747170148</span><br/>
            IBAN : <span class="text-gray-900 font-weight-bolder">SN08SN0120120303615747170148</span><br/>
            Code SWIFT : <span class="text-gray-900 font-weight-bolder">CBAOSNDA</span><br/>
        </p>
     </div></div><!----></div></div>

<!---- Start PP field  ----->
                                            <fieldset class="col-xl-12 pl-0 pr-0  margin-bottom"
                                                style="border: none !important" :disabled="!canEdit">
                                                <div v-for="(input, index) in attachsList" :key="'al_' + index">
                                                    <div v-if="input.code=='payp'">
                                                    <div  class="card p-0 upldfl-pay"
                                                        v-bind:class="[index > 0 ? 'mt-8 mt-lg-8' : '']"
                                                        v-bind:data="input.name" style="background-color: #F3F6F9;"
                                                        v-bind:style="input.name != '' ? 'border: solid 1px #1BC5BD' : 'border: 1px solid rgba(43, 51, 76,.3) !important;'">
                                                        <div class="card-body pt-5 pb-5 d-flex align-items-center justify-content-between">
                                                            <div class="mr-4 ">
                                                                <h4 class="text-dark mb-0">{{ input.libelle }} </h4>
                                                                <!--<p class="text-dark-50 font-size-lg">{{ input.description }}</p>-->
                                                            </div>
                                                            <div v-if="input.name !== ''">
                                                                <a href="#"
                                                                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                                                    {{ input.name }}
                                                                </a>
                                                                <button
                                                                    class="btn font-weight-bolder font-size-lg btn-danger btn-sm ml-4"
                                                                    :data-id="input.id"
                                                                    @click="deleteFile">Supprimer&nbsp;<i
                                                                        class="flaticon2-rubbish-bin icon-md"></i>
                                                                </button>
                                                            </div>
                                                            <div v-else>
                                                                <button
                                                                    class="btn font-weight-bolder font-size-lg btn-primary btn-sm"
                                                                    :data-id="input.id" :data-name="input.libelle"
                                                                    @click="showModal">Ajouter&nbsp;<i
                                                                        class="flaticon-upload icon-md"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="input.name === ''" class="text-danger upldfl-pay-error">Ce document est obligatoire</div>
                                                </div>
                                                </div>
                                            </fieldset>

<!---- End PP field ----->

                                                <div class="card  p-0 mb-8 mb-lg-8" style="background-color: #F3F6F9;">
                                                    <div
                                                        class="card-body d-flex align-items-center justify-content-between">
                                                        <h6 class="mr-4 ml-4">
            
                                                                <input type="checkbox" class="custom-control-input probation-check" v-model="probation" name="checkbox-1" id="checkbox-1" @change="updateProbation">
                                                                        <label class="custom-control-label" for="checkbox-1">
                                                                            J'approuve que l'École de Médecine Saint-Christopher Iba Mar DIOP se réserve le droit d'accepter ou de refuser toute demande. Je déclare vraies toutes les informations ci-dessus écrites par moi, et j'accepte la responsabilité de payer tous les frais d'inscription et d'études. Je me conformerai au règlement intérieur de l'École de Médecine Saint-Christopher Iba Mar DIOP. Toute demande acceptée par l'École de Médecine Saint-Christopher Iba Mar DIOP sur la base d'informations fausses sera rejetée et tout diplôme obtenu dans ce cas sera déclaré nul.
                                                                        </label>
                                                                </h6>
                                                    </div>
                                                </div>
                                            </template>


                                            <div v-if="form.status === 2 || form.status === 3"
                                                class="alert alert-custom alert-light-primary mb-5 p-5" role="alert">
                                                <div class="alert-text">
                                                    <h3 class="alert-heading">Merci pour votre candidature, votre dossier a été envoyé à l’établissement pour traitement !</h3>
                                                    <hr>
                                                    <p class="font-size-h5">Vous allez recevoir un email de notification une fois que votre dossier est traité</p>

                                                </div>
                                            </div>
                                            <div v-if="form.status === 4">
                                                <div v-for="(input, index) in rejets" :key="'rj_' + index">
                                                    <div class="card p-0 mb-6 mb-lg-6"
                                                        style="background-color: #F3F6F9;border: 1px solid rgba(43, 51, 76,.3) !important;">
                                                        <div
                                                            class="card-body d-flex align-items-center justify-content-between">
                                                            <div class="mr-4">
                                                                <p class="text-dark-50 font-size-lg">
                                                                    {{ input.libelle }}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-between border-top pt-10">
                                            <div class="mr-2">
                                                <button
                                                    class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                                                    data-wizard-type="action-prev">
                                                    <i class="flaticon2-left-arrow pr-5"></i>Précédent
                                                </button>
                                            </div>
                                            <div>
                                                <button v-if="form.status === 1 || form.status === 4"
                                                    class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                                                    data-wizard-type="action-submit" @click="submitData">
                                                    Valider<i class="flaticon2-check-mark pl-5"></i>
                                                </button>
                                                <button
                                                    class="btn btn-primary font-weight-bold text-uppercase px-9 py-4 float-right"
                                                    data-wizard-type="action-next">
                                                    Suivant<i class="flaticon2-right-arrow pl-5"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <b-modal ref="modalFile" no-close-on-backdrop no-close-on-esc hide-header-close v-bind:title="file.title">
                <template #default>
                    <b-form-file accept=".jpg, .png, .pdf, .jpeg,.tiff" v-model="file.file"
                        placeholder=""></b-form-file>
                </template>
                <template #modal-footer>
                    <div class="w-100">
                        <button class="btn btn-secondary" @click="hideModal">Annuler</button>
                        <button class="btn btn-primary float-right" @click="addFile">Enregistrer&nbsp;<i
                                class="flaticon-upload icon-md"></i></button>
                    </div>
                </template>
            </b-modal>
        </div>
    </div>

</template>
<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import Excluded from "@/assets/plugins/formvalidation/dist/es6/plugins/Excluded";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/fr';
import KTUtil from "@/assets/js/components/util";
import Swal from "sweetalert2";
import Layout from "@/view/layout/Layout.vue";
import { mask } from 'vue-the-mask'

export default {
    metaInfo: { title: 'Tableau de bord' },
    directives: {
        mask
    },
    components: {
        DatePicker
    },
    layout: Layout,
    props: {
        select: String,
        user: Object,
        pays: Array,
        attachs: Array,
        langues: Array,
        num_dossier: String,
        rejets: Array,
        status: Number,
        sessions: Array,
    },
    data() {
        return {
            canEdit: false,
            attachsList: this.attachs,
            optionsPhone: { placeholder: "Numéro de téléphone", name: "telephone" },
            autoDefaultCountryPhone: false,
            autoDefaultCountryfax: true,
            optionsFax: { placeholder: "Numéro de fax", name: "fax" },
            bindProps: { mode: 'international' },
            admissions: ['Médecine', 'Dentaire', 'Pharmacie'],
            form: {
                num_dossier: '',
                register: '',
                nom: '',
                prenom: '',
                identity:'',
                sexe: '',
                nationality: '',
                birth_date: '',
                birth_place: '',
                birth_city: '',
                address: '',
                phone: null,
                fax: null,
                email: '',
                family_situation: '',
                bac_series: '',
                bac_year: '',
                bac_mention: '',
                bac_school: '',
                admission1: '',
                admission2: '',
                admission3: '',
                admission: [],
                national_language: '',
                health_problems: '',
                medical_supervision: '',
                medical_universities: [],
                other_universities: [],
                spoken_languages: [],
                status: 0,
                session: '',
            },
            isDisabled: false,
            validphone: '',
            validfax: '',
            file: {
                file: null,
                upid: null,
                title: null
            },
            probation: 0,
            wizard: null,
            validations: [],
            fl0: '',
            form_steps: null,
            defaultValue: null
        }
    },
    mounted() {
        this.form = Object.assign({}, this.user);

        if (this.user.status === 1 || this.user.status === 4) {
            this.canEdit = true;
        }

        if (this.user.phone == "") {
            this.autoDefaultCountryPhone = true;
        }

        if (this.user.fax == "") {
            this.autoDefaultCountryfax = true;
        }

        if (this.user.admission !== null) {
            this.form.admission1 = this.user.admission[0];
            this.form.admission2 = this.user.admission[1];
            this.form.admission3 = this.user.admission[2];
        }

        if(this.user.birth_date === null){
            this.defaultValue = this.less15years()
        }        

        this.form.session = this.user.session_year + '+' + this.user.session_month;

        this.form_steps = KTUtil.getById("kt_form");

        const wizardEl = document.querySelector('#kt_wizard_v1');
        const prevButton = wizardEl.querySelector('[data-wizard-type="action-prev"]');
        const nextButton = wizardEl.querySelector('[data-wizard-type="action-next"]');
        const submitButton = wizardEl.querySelector('[data-wizard-type="action-submit"]');

        const wizard = new KTWizard("kt_wizard_v1", {
            startStep: 1,
            clickableSteps: false,
            navigation: false
        });

        this.validations.push(formValidation(
            this.form_steps,
            {
                fields: {
                    nom: {
                        validators: {
                            notEmpty: {
                                message: "Ce champs est obligatoire",
                            },
                        }
                    },
                    prenom: {
                        validators: {
                            notEmpty: {
                                message: "Ce champs est obligatoire",
                            },
                        }
                    },
                    sexe: {
                        validators: {
                            notEmpty: {
                                message: "Ce champs est obligatoire",
                            },
                        }
                    },
                    situation_famille: {
                        validators: {
                            notEmpty: {
                                message: "Ce champs est obligatoire",
                            },
                        }
                    },
                    nationalite: {
                        validators: {
                            notEmpty: {
                                message: "Ce champs est obligatoire",
                            },
                        }
                    },
                    date_naissance: {
                        validators: {
                            notEmpty: {
                                message: "Ce champs est obligatoire",
                            },
                        }
                    },
                    lieu_naissance: {
                        validators: {
                            notEmpty: {
                                message: "Ce champs est obligatoire",
                            },
                        }
                    },
                    adresse: {
                        validators: {
                            notEmpty: {
                                message: "Ce champs est obligatoire",
                            },
                        }
                    },
                    email: {
                        validators: {
                            notEmpty: {
                                message: "Ce champs est obligatoire",
                            },
                            emailAddress: {
                                message: 'La valeur n\'est pas une adresse e-mail valide'
                            }
                        }
                    },
                    telephone: {
                        validators: {
                            notEmpty: {
                                message: "Ce champs est obligatoire",
                            },
                            stringLength: {
                                min: 2,
                                message: "N° de Téléphone invalide",
                            }
                        }
                    },
                    premier_choix: {
                        validators: {
                            notEmpty: {
                                message: "Ce champs est obligatoire",
                            },
                        }
                    },
                    deuxieme_choix: {
                        validators: {
                            notEmpty: {
                                message: "Ce champs est obligatoire",
                            },
                        }
                    },
                    troisieme_choix: {
                        validators: {
                            notEmpty: {
                                message: "Ce champs est obligatoire",
                            },
                        }
                    },
                    bac_serie: {
                        validators: {
                            notEmpty: {
                                message: "Ce champs est obligatoire",
                            },
                        }
                    },
                    bac_year: {
                        validators: {
                            notEmpty: {
                                message: "Ce champs est obligatoire",
                            },
                        }
                    },
                    bac_mention: {
                        validators: {
                            notEmpty: {
                                message: "Ce champs est obligatoire",
                            },
                        }
                    },
                    bac_school: {
                        validators: {
                            notEmpty: {
                                message: "Ce champs est obligatoire",
                            },
                        }
                    },
                    national_language: {
                        validators: {
                            notEmpty: {
                                message: "Ce champs est obligatoire",
                            },
                        }
                    },
                    health_problems: {
                        validators: {
                            notEmpty: {
                                message: "Ce champs est obligatoire",
                            },
                        }
                    },
                    medical_supervision: {
                        validators: {
                            notEmpty: {
                                message: "Ce champs est obligatoire",
                            },
                        }
                    },
                },
                plugins: {
                    trigger: new Trigger(),
                    submitButton: new SubmitButton(),
                    bootstrap: new Bootstrap(),
                    excluded: new Excluded({
                        excluded: function (field, ele, eles) {
                            const selectedCheckbox = document.querySelector('[name="health_problems"]:checked');
                            const framework = selectedCheckbox ? selectedCheckbox.value : '';
                            return (field === 'medical_supervision' && framework !== '1') || (field === 'health_problems' && framework === '1');
                        }
                    })
                }
            }
        ));
        prevButton.addEventListener('click', function () {
            wizard.goPrev();
        });
        nextButton.addEventListener('click', () => {
            if (wizard.getStep() === 1) {
                let validator = this.validations[0]
                validator.validate().then(status => {
                    if (status === 'Valid') {
                        wizard.goNext();
                    } else {
                        Swal.fire({
                            text: "Veuillez completer les données du formulaire",
                            icon: "warning",
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false
                        })
                    }
                });
            }
            if (wizard.getStep() === 2) {
                if (this.ValidateFiles("upldfl")) {
                    wizard.goNext();
                } else {
                    Swal.fire({
                        text: "Veuillez télécharger les fichiers nécessaires",
                        icon: "warning",
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false
                    })
                }
            }
          
        });

     

            
                
        
    },
    methods: {
            less15years(){
            var d  = new Date();
            let y = d.getFullYear() - 15
            let dt = d.setFullYear(y);
            return new Date(dt);
        },
        disabledRange (date) {
            return date > this.less15years();
        } ,       
        ValidateFiles(Validationcls) {
            let upldfl = KTUtil.getByClass(Validationcls);
            let bool = true;
            let cnt = 0;
            for(let i=0; i < upldfl.length; i++){
                let fl = upldfl[i].getAttribute('data')
                if (fl === '') {
                    upldfl[i].style.borderColor = 'rgba(255, 0, 0, 0.5)';
                    cnt++;
                } else {
                    upldfl[i].style.borderColor = 'rgb(27, 197, 189)';
                }                
            }
            if (cnt > 0) {
                bool = false
            }
            return bool
        },
        updateProbation(event) {
    this.probation = event.target.checked ? 1 : 0;
  },
        
        submitData(e) {
            if (this.user.status === 4) {
                this.probation = 1;
            }
            if (this.ValidateFiles("upldfl-pay")) {
                if (this.probation === 0) {
                Swal.fire({
                    icon: "warning",
                    title: "",
                    text: "Veuillez accepter les conditions d'inscription avant de soumettre votre dossier",
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false
                }); 
            } else {
                Swal.fire({
                    title: "Êtes vous sûre ?",
                    html: "Voulez-vous soumettre votre dossier à l'administration ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Oui , soumettre mon dossier",
                    cancelButtonText: "Annuler",
                    customClass: {
                        confirmButton: 'btn btn-success',
                        cancelButton: 'btn btn-secondary'
                    },
                    buttonsStyling: false
                }).then(result => {
                    if (result.value) {
                        this.axios.post('admission/inscription/submit')
                            .then(resp => {
                                if (resp.data.validation) {
                                    this.$inertia.get('/admission/inscription');
                                } else {
                                    Swal.fire({
                                        icon: "error",
                                        title: "",
                                        text: "L'opération a échoué. Veuillez réessayer à nouveau",
                                        customClass: {
                                            confirmButton: 'btn btn-primary',
                                        },
                                        buttonsStyling: false
                                    });
                                }
                            })
                    }
                });
            }
                
                    } else {
                        Swal.fire({
                            text: "Veuillez uploader la preuve de réglement",
                            icon: "warning",
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false
                        })
                    }
           
        },
        getCurrentYear() {
            var max = new Date().getFullYear()
            var min = max - 10
            var years = []

            for (var i = max; i >= min; i--) {
                years.push(i)
            }
            return years;
        },
        goNext() {
            this.$refs['my-modal'].hide()
        },
        addInputMedical() {
            this.form.medical_universities.push({ 'faculty': '', 'year': '', 'reason': '' })
        },
        addInputInstitut() {
            this.form.other_universities.push({ 'faculty': '', 'period': '' })
        },
        addInputLanguage() {
            this.form.spoken_languages.push('')
        },
        deleteInput(index, obj, field) {
            obj.splice(index, 1);
            this.saveData(field, obj)
        },
        handleChangeAdmission() {
            this.form.admission = [this.form.admission1, this.form.admission2, this.form.admission3];
            this.saveData('admission', this.form.admission)
        },
        handleBlurUniversity() {
            this.form.medical_universities.forEach((value, index) => {
                let data = {
                    faculty: this.$refs.university[index].value,
                    year: this.$refs.university_year[index].value,
                    reason: this.$refs.university_raisons[index].value
                };
                this.form.medical_universities[index] = data
            })
            this.saveData('medical_universities', this.form.medical_universities)
        },
        handleBlurUniversityOther() {
            this.form.other_universities.forEach((value, index) => {
                let data = {
                    faculty: this.$refs.university_other[index].value,
                    period: document.getElementById('university_other_year_' + index).value
                };
                this.form.other_universities[index] = data
            })
            this.saveData('other_universities', this.form.other_universities)
        },
        handleBlurLanguagesTalk() {
            this.form.spoken_languages.forEach((value, index) => {
                this.form.spoken_languages[index] = this.$refs.languages_talk[index].value
            })
            this.saveData('spoken_languages', this.form.spoken_languages)
        },
        saveData(field, value) {
            if (this.user.status === 1 || this.user.status === 4) {
                let data = {}
                let txt = ''

                if (field == 'session') {
                    let sessions = value.split('+')
                    data['session_year'] = sessions[0]
                    data['session_month'] = sessions[1]
                } else {
                    if (field == 'phone') {
                        if (!this.validphone && value !== '') {
                            Swal.fire({
                                icon: "error",
                                title: "",
                                text: "N° de téléphone erroné.",
                                heightAuto: false,
                            });
                            return
                        }
                    } else if (field == 'fax') {
                        if (!this.validfax && value !== '') {
                            Swal.fire({
                                icon: "error",
                                title: "",
                                text: "N° de fax erroné.",
                                heightAuto: false,
                            });
                            return
                        }
                    }
                    data[field] = value;
                }
                this.axios.post('/register/inscription', data)
            }
        },
        validePhone(phoneData) {
            /*this.validphone=phoneData.valid
            this.form.phone=phoneData.number
            if(typeof this.validphone==='undefined'){
            this.validphone=true
            this.form.phone=''
            }*/
            if (typeof phoneData.valid !== "undefined") {
                this.validphone = phoneData.valid
                this.form.phone = phoneData.valid ? phoneData.number : ''
            }
        },
        valideFax(faxData) {
            /*this.validfax=faxData.valid
            this.form.fax=faxData.number
            if(typeof this.validfax==='undefined'){
            this.validfax=true
            this.form.fax=''
            }*/
            if (typeof faxData.valid !== "undefined") {
                this.validfax = faxData.valid
                this.form.fax = faxData.valid ? faxData.number : ''
            }
        },
        checkHealth(field, value) {
            this.saveData(field, value)
            if (value == 1) {
                this.isDisabled = false
            } else {
                this.isDisabled = true
                this.form.medical_supervision = ''
                this.saveData('medical_supervision', this.form.medical_supervision)
            }
        },
        addFile(event) {
            let formData = new FormData();
            formData.append('attach', this.file.upid);
            formData.append('file', this.file.file);

            event.target.classList.add("spinner", "spinner-light", "spinner-right");

            this.axios.post('admission/inscription/file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(resp => {
                if (resp.data.validation) {
                    this.getFiles();
                    this.file.file = null
                   // setTimeout(() => {
                        this.ValidateFiles("upldfl");
                   // }, 50);
                } else {
                    Swal.fire({
                        title: "",
                        text: "Aucun fichier n'est envoyé ou le format du fichier est incorrect",
                        icon: "error",
                        heightAuto: false,
                    });
                }
                event.target.classList.remove("spinner", "spinner-light", "spinner-right");
            })
        },
        showModal(event) {
            this.file.upid = event.target.getAttribute('data-id');
            this.file.title = event.target.getAttribute('data-name');
            this.$refs['modalFile'].show()
        },
        hideModal() {
            this.$refs['modalFile'].hide()
        },
        getFiles() {
            this.axios.post('admission/inscription/files')
                .then(resp => {
                    this.hideModal();
                    this.attachsList = resp.data.files;

                })
        },
        deleteFile(event) {
            let data = {};
            data['attach'] = event.target.getAttribute('data-id');

            Swal.fire({
                title: "Êtes vous sûre?",
                text: "Voulez-vous supprimer le fichier",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Oui , supprimer le ficher",
                cancelButtonText: "Annuler",
                customClass: {
                    confirmButton: 'btn btn-danger',
                    cancelButton: 'btn btn-secondary'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.axios.post('admission/inscription/file/delete', data)
                        .then(resp => {
                            this.attachsList = resp.data.files;
                        })
                }
            });
        }
    }
}
</script>
<style>
.custom-file-input~.custom-file-label[data-browse]::after {
    content: "Parcourir";
}
</style>
