<template>
    <div class="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10 admission-landing-aside landing-bg">
        <div class="d-flex flex-row-fluid flex-column justify-content-between">
            <a href="/" class="flex-column-auto mt-5 pb-lg-0 pb-10">
                 <img src="/media/logos/ecole-logo.png" alt="école de medecine saint christopher iba mar diop" class="logo"> 
            </a>
            <div class=" landing-content-title flex-column-fluid d-flex flex-column justify-content-center">
               <div class="welcome">
                    <hr class="horizontal">
                    <hr class="vertical">
                    <p class="font-weight-lighter text-white ">
                        Déjà inscrit&nbsp;? Connectez-vous pour accéder à votre parcours vers devenir médecin, pharmacien, ou dentiste à l'Université St.&nbsp;Christopher.
                    </p>
                    <hr class="vertical ii">
                    <hr class="horizontal ii">
                </div>
            </div>
        </div>

 
    </div>
</template>
<script>
	export default{
		
	}
</script>