<template>
   <div class="d-flex flex-column flex-root  bg-white">
      <section class="mt-5 mb-5">
         <div class="container text-center">
            <img src="/media/logos/ecole-logo.png" class="max-h-200px">
         </div>
      </section>
      <div class=" d-flex-content d-flex flex-column flex-lg-row flex-column-fluid bg-white" >
         <div class="form-landing-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">

            <div class="d-flex flex-column-fluid justify-content-center ">

               <div class="w-50">

                  <div class="form-title text-center" style="font-size: 1.5rem;font-weight: 900;margin-bottom: 2rem;">
                     Validation d'une attestation d'inscription
                  </div>
                  <div class="form-group row ">
                     <div class="col-md-12  mt-5 mb-5 pl-10"  style="font-size: 1.2rem;">
                        <p>
                           Le présent service vous permet de valider l'authenticité d'une attestation d'inscription d'un(e) étudiant(e) de l'école de médecine ST CHRISTOPHER IBA DIOR.
                        </p>
                        <p>
                           Inscrivez le numéro de validation de l'attestation d'inscription dans le champ ci-dessous. Une copie de cette attestation s'affichera à l'écran.
                        </p>
                        <p style="font-weight: 600">
                           Assurez-vous que les informations affichées à l'écran sont identiques aux informations inscrites sur l'attestation d'inscription que vous avez reçue.
                        </p>
                        <label style="font-size: 1.1rem;font-weight: 600">Numéro de validation</label>
                        <form method="POST" action="/validation">
                           <input type="text" class="form-control col-3" v-model="form.numero"  autocomplete="off">
                        </form>
                        <button class="btn btn-primary mt-5" @click="check">Valider l'attestation</button>
                     </div>
                  </div>
                </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
   data() {
      return {
         form: {
            numero: "",
         }
      }
   },
   methods: {
      check(){
         let that =this;
         this.axios({
            url: '/validation/check',
            method: 'POST',
            data: that.form
         }).then((response) => {
            if(response.data.validation){
               this.show();
            }else{
               Swal.fire({
                  title: "Aucune attestation d'inscription ne correspond à ce numéro.",
                  icon: "warning",
                  confirmButtonText: "Fermer",
                  customClass: {
                     confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false
               })
            }
         });
      },
      show(){
         let that =this;
         this.axios({
            url: '/validation/show',
            method: 'POST',
            responseType: 'blob',
            data: that.form
         }).then((response) => {
            const file = new Blob([response.data],{type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            window.location.replace(fileURL);
         });
      }
   }
}

</script>
