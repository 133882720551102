<template>
    <div class="d-flex flex-column flex-lg-row flex-column-fluid bg-white">
        <Aside></Aside>
        <div class="d-flex flex-column flex-row-fluid position-relative p-7 pl-20 pr-20 overflow-hidden">
            <div class="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">

                <form class="form" novalidate="novalidate" id="forgot_form">
                      <div class="logo-holder text-center mb-20 logo-mobile">
                    <img src="/media/logos/ecole-logo.png" alt="école de medecine saint christopher iba mar diop" class="max-w-200px "> 
                    </div>
                    
                    <div class="landing-intro pb-13 pt-lg-0 pt-5 ">
                        <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">Mot de passe oublié</h3>
                        <p class=" font-size-h4 ">
                            Veuillez indiquer votre adresse e-mail pour réinitialiser votre mot de passe.
                        </p>
                    </div>
                    <div class="form-group">
                        <input
                            class="form-control form-control-solid h-auto py-5 px-4  font-size-h6"
                            type="email"
                            placeholder="Email"
                            name="email"
                            v-model="form.email"
                            autocomplete="off"
                        >
                    </div>
                    <div class="form-group d-flex flex-wrap justify-content-between align-items-center">
                       <button
                            ref="forgot_submit"
                            class="btn btn-primary cta-btn font-weight-bolder font-size-h6 px-8 py-4 my-3 btn-lg  btn-block"><span class="title">Envoyer</span>
                        </button>
                        <div class="divider"><hr/><span>OU</span></div>
                        <a href="javascript:;" v-on:click="signin" class="btn cta-btn btn-outline-primary btn-block btn-lg mb-10"><span class="title">Portail des admissions</span><br/>Compléter ou vérifier le statut de sa candidature</a>
                    </div>

                </form>
            </div>
        </div>

    </div>
</template>
<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";
import Swal from "sweetalert2";
import Aside from "@/view/pages/Web/Auth/Aside"

export default {
    data() {
        return {
            fv:null,
            validphone:'',
            form: {
                email: "",
            },
        }
    },
    components: {
        Aside
    },     
    mounted() {
        const forgot_form = KTUtil.getById("forgot_form");
        const submitButton = this.$refs["forgot_submit"];

        this.fv = formValidation(forgot_form, {
            fields: {
                email: {
                    validators: {
                        notEmpty: {
                            message: "E-amil obligatoire",
                        },
                        emailAddress:{
                            message: "Adresse e-mail invalide",
                        }
                    },
                },
            },
            plugins: {
                trigger: new Trigger(),
                submitButton: new SubmitButton(),
                bootstrap: new Bootstrap(),
            },
        });
        submitButton.addEventListener('click', () => {
            this.fv.validate().then(status => {
                if (status === 'Valid') {
                    this.sendData();
                }
            })
        })
    },
    methods: {
        signin(){
            this.$inertia.get('login')
        },
        sendData(){
            var email = this.form.email;

            const submitButton = this.$refs["forgot_submit"];
            submitButton.classList.add("spinner", "spinner-light", "spinner-right");

            this.axios
                .post('admission/forget-password', { email })
                .then(response => {
                    if(response.data.validation){
                        this.$inertia.get('/admission/notify')
                    }else{
                        Swal.fire({
                            title: "",
                            html: response.data.message,
                            icon: "error",
                            confirmButtonClass: "btn btn-danger",
                            heightAuto: false,
                        });
                        if (typeof response.data.validation.list !== "undefined") {
                            response.data.validation.list.forEach(function(item, index){
                                this.fv.updateFieldStatus(item,'Invalid')
                            })
                        }
                    }
                })
            submitButton.classList.remove("spinner","spinner-light","spinner-right");

        },
        signin(){
            this.$inertia.get('/admission/login')
        },
    }
}
</script>
