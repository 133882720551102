<template>
	<div class="contaner-fluid">
		<div class="row">
			<div class="col-xl-12">
				<div class="alert inpage-alert alert-custom alert-light-danger mb-5 p-5" role="alert">
					<div class="alert-text">
						<h3 class="alert-heading">
							Les inscriptions sont actuellement fermées.
						</h3>
						<p class="font-size-h5">Veuillez retourner au site web ou prendre contact avec l’établissement pour connaître les dates d’ouverture des inscriptions.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Layout from "@/view/layout/Layout.vue";
export default {
	metaInfo: { title: "Tableau de bord" },
	layout: Layout,
	props: {
		num_dossier: String,
	},
};
</script>
