<template>
    <ul class="menu-nav" v-if="currentUser.role === 'admin'">
        <li v-if="currentUser.profile === 'administrateur'" aria-haspopup="true" data-menu-toggle="hover" class="menu-item">
            <a
                href="javascript:;"
                class="menu-link"
                data-url="/admin/dashboard"
                v-on:click="goPage"
            >
                <i class="menu-icon flaticon2-architecture-and-city"></i>
                <span class="menu-text">Tableau de bord</span>
            </a>
        </li>
        <li class="menu-section">
            <h4 class="menu-text">Admissions</h4>
            <i class="menu-icon flaticon-more-v2"></i>
        </li>
        <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item">
            <a
                href="javascript:;"
                class="menu-link"
                data-url="/admin/inscriptions"
                v-on:click="goPage"
            >
                <i class="menu-icon flaticon2-hourglass-1"></i>
                <span class="menu-text">Nouvelles Inscriptions</span>
            </a>
        </li>
        <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item">
            <a
                href="javascript:;"
                class="menu-link"
                data-url="/admin/transfers"
                v-on:click="goPage"
            >
                <i class="menu-icon flaticon2-world"></i>
                <span class="menu-text">Demandes de Transfert</span>
            </a>
        </li>
        <template v-if="currentUser.profile === 'administrateur'">
            <li class="menu-section">
                <h4 class="menu-text">Configuration</h4>
                <i class="menu-icon flaticon-more-v2"></i>
            </li>
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item">
                <a
                    href="javascript:;"
                    class="menu-link"
                    data-url="/admin/configuration/users"
                    v-on:click="goPage"
                >
                    <i class="menu-icon flaticon2-group"></i>
                    <span class="menu-text">Utilisateurs </span>
                </a>
            </li>
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item">
                <a
                    href="javascript:;"
                    class="menu-link"
                    data-url="/admin/configuration/sessions"
                    v-on:click="goPage"
                >
                    <i class="menu-icon flaticon2-calendar-9"></i>
                    <span class="menu-text">Sessions d'inscription</span>
                </a>
            </li>
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item">
                <a
                    href="javascript:;"
                    class="menu-link"
                    data-url="/admin/configuration/steps"
                    v-on:click="goPage"
                >
                    <i class="menu-icon flaticon-light"></i>
                    <span class="menu-text">Etapes de traitement </span>
                </a>
            </li>
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item">
                <a
                    href="javascript:;"
                    class="menu-link"
                    data-url="/admin/configuration/unite"
                    v-on:click="goPage"
                >
                    <i class="menu-icon flaticon2-open-text-book"></i>
                    <span class="menu-text">Unités d'enseignement </span>
                </a>
            </li>
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item">
                <a
                    href="javascript:;"
                    class="menu-link"
                    data-url="/admin/configuration/rejets"
                    v-on:click="goPage"
                >
                    <i class="menu-icon flaticon-warning-sign"></i>
                    <span class="menu-text">Motifs de rejet </span>
                </a>
            </li>
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item">
                <a
                    href="javascript:;"
                    class="menu-link"
                    data-url="/admin/configuration/emails"
                    v-on:click="goPage"
                >
                    <i class="menu-icon flaticon2-mail-1"></i>
                    <span class="menu-text">E-mails </span>
                </a>
            </li>
        </template>
    </ul>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "KTMenu",
    computed: {
        ...mapGetters(["currentUser"])
    },
    methods: {
        goPage: function(event) {
            const el = event.target.parentElement;
            const url = el.getAttribute("data-url");

            if (url !== null) {
                this.$inertia.get(url);
                document.querySelectorAll("li").forEach(function(elm) {
                    elm.classList.remove(
                        "menu-item-active",
                        "menu-item-active"
                    );
                });
                el.parentElement.classList.add(
                    "menu-item-active",
                    "menu-item-active"
                );
            }
        }
    }
};
</script>
