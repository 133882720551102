import Vue from "vue";
import VueEvents from 'vue-events';
import "bootstrap-vue/dist/bootstrap-vue.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "socicon/css/socicon.css";
import "animate.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "line-awesome/dist/line-awesome/css/line-awesome.css";
import "@/assets/plugins/flaticon/flaticon.css";
import "@/assets/plugins/flaticon2/flaticon.css";
import "@/assets/plugins/keenthemes-icons/font/ki.css";
import "@/assets/sass/style.vue.scss";

import { App, plugin } from '@inertiajs/inertia-vue'
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
//import MockService from "@/core/mock/mock.service";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
//import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
//import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@/core/plugins/validation";


import "@mdi/font/css/materialdesignicons.css";

import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
Vue.use(VueMoment, {
    moment,
})

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
Vue.use(VueTelInput);
//Vue.use(require('vue-moment'));
import InputMask from 'vue-input-mask';
Vue.component('input-mask', InputMask)

Vue.use(VueEvents);
// API service init
ApiService.init();

store.dispatch(VERIFY_AUTH)

Vue.use(plugin)
const el = document.getElementById('app')

process.env.BASE_URL = '/'

new Vue({
    store,
    i18n,
    vuetify,
    render: h => h(App, {
        props: {
            initialPage: JSON.parse(el.dataset.page),
            resolveComponent: name => require(`./view/pages/${name}`).default,
        },
    })
}).$mount(el);


