<template>
    <div class="container-fluid">
<div class="row flex-center">
    <div class="col-lg-8 pb-12 pt-10 text-center">
<h3 class="display-4">Veuillez choisir la rubrique qui correspond à votre demande</h3>
</div>
</div>

        <div class="row flex-center">
            <div class="col-lg-4">
                <div class="card card-custom card-stretch gutter-b">
                    <div class="card-header border-0 pt-5" style="justify-content:center !important">
                        <h3 class="card-title font-weight-bolder">
                            Nouvelle inscription
                        </h3>
                    </div>
                    <div class="card-body d-flex flex-column">
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 286.4 139.4" style="max-width: 40%;margin: auto;" xml:space="preserve">

<g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
	<path class="st0" d="M68.6,36.1h-5.8v-5.8h5.8C68.6,30.3,68.6,36.1,68.6,36.1z M64,34.8h3.3v-3.3H64V34.8z"/>
	<path class="st0" d="M87.5,36.1h-5.8v-5.8h5.8V36.1z M82.9,34.8h3.3v-3.3h-3.3V34.8z"/>
	<path class="st0" d="M68.5,13.7h-5.8V8h5.8L68.5,13.7L68.5,13.7z M63.9,12.5h3.3V9.2h-3.3V12.5z"/>
	<path class="st0" d="M76,13.7h-5.8V8H76L76,13.7L76,13.7z M71.4,12.5h3.3V9.2h-3.3L71.4,12.5L71.4,12.5z"/>
	<path class="st0" d="M68.5,21.2h-5.8v-5.8h5.8L68.5,21.2L68.5,21.2z M63.9,19.9h3.3v-3.3h-3.3V19.9z"/>
	<path class="st0" d="M76,21.2h-5.8v-5.8H76L76,21.2L76,21.2z M71.4,19.9h3.3v-3.3h-3.3L71.4,19.9L71.4,19.9z"/>
	<path class="st0" d="M68.5,28.6h-5.8v-5.8h5.8L68.5,28.6L68.5,28.6z M63.9,27.4h3.3v-3.3h-3.3V27.4z"/>
	<path class="st0" d="M76,28.6h-5.8v-5.8H76L76,28.6L76,28.6z M71.4,27.4h3.3v-3.3h-3.3L71.4,27.4L71.4,27.4z"/>
	<path class="st0" d="M87.3,21.2h-5.8v-5.8h5.8V21.2z M82.7,19.9h3.3v-3.3h-3.3V19.9L82.7,19.9z"/>
	<path class="st0" d="M87.3,28.6h-5.8v-5.8h5.8V28.6z M82.7,27.4h3.3v-3.3h-3.3V27.4L82.7,27.4z"/>
	<path class="st0" d="M90.7,41.7V12.5H79.4V5.2H59.3v36.4H57v1.2h13.3h5.8H93v-1.2H90.7z M89.4,13.7v27.9h-10V13.7H89.4z M71.6,41.7
		V31.6h3.3v10.1L71.6,41.7L71.6,41.7z M70.3,30.3v11.3h-9.8V6.5h17.6v35.2h-2V30.3H70.3z"/>
</g>
<g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
	<path class="st0" d="M55.3,24.3C55.3,24.3,55.3,24.2,55.3,24.3C55.3,24.2,55.3,24.2,55.3,24.3c0-0.1,0-0.2,0-0.2c0,0,0-0.1,0-0.1
		c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1L50.5,19c-0.2-0.2-0.5-0.2-0.7,0c-0.2,0.2-0.2,0.5,0,0.7
		l3.9,3.9H32.5c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5h21.1l-3.9,3.9c-0.2,0.2-0.2,0.5,0,0.7c0.1,0.1,0.2,0.2,0.4,0.2
		s0.3-0.1,0.4-0.2l4.7-4.7C55.2,24.4,55.3,24.4,55.3,24.3C55.3,24.3,55.3,24.3,55.3,24.3C55.3,24.3,55.3,24.3,55.3,24.3z"/>
</g>
<g id="layer1" transform="translate(-169.77 -382.33)">
	<g id="g9357" transform="translate(-256.58 42.426)">
		<path id="path9347" class="st0" d="M494.6,366.4c0,5-4,9-9,9c-5,0-9-4-9-9c0-5,4-9,9-9C490.5,357.4,494.6,361.4,494.6,366.4
			L494.6,366.4z"/>
		<path id="path9349" class="st0" d="M471.8,394c0,0-7.4,38.3-9,40.9c-1.4,2.2-12,19.6-12,19.6c-2.3,3.7-0.1,6.4,2.3,7.8
			c2,1.2,6.4,0.9,7.9-1.6c0,0,11.8-18.3,13.1-22.3c0.9-2.7,3-14.1,3-14.1s11.4,11.8,12.7,13.9c1.6,2.7,4.5,21.2,4.5,21.2
			c0.7,3.3,3.8,4.4,7.1,3.8c2.6-0.4,5.3-2.2,4.7-5.4c0,0-2.6-21.5-4.7-25c-2.5-4.2-15.8-18.6-15.8-18.6l3.8-17c0,0,2.5,5,4.2,7.1
			c2,2.4,14.3,9.6,14.3,9.6c1.1,0.8,3.7,0.3,4.7-1.6c0.8-1.5,0.8-4-1-5.2c0,0-10.1-6.4-12.5-8.3c-2.6-2.2-8-16.9-8-16.9
			c-1.7-2.3-3.9-4.8-7.8-4.9c-3.9-0.1-5.9,2.1-7.6,3.3c0,0-15,11-16.5,13.2c-1.5,2.2-4.2,19.1-4.2,19.1c-0.4,1.9,0.3,3.5,2.6,4
			c2.8,0.6,4.8-1.1,5-2.4c0,0,1.7-13.3,3-15.3C466.9,396.7,471.8,394,471.8,394L471.8,394z"/>
	</g>
</g>
</svg>
                        <div class="pt-5">
                            <button
                                @click="setSession($event, 'I')"
                                class="btn btn-lg btn-primary btn-shadow-hover font-weight-bolder w-100 py-3"
                                data-title="candidature"
                            >
                                Déposer un dossier de candidature
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="card card-custom card-stretch gutter-b">
                    <div class="card-header border-0 pt-5" style="justify-content: center !important;">
                        <h3 class="card-title font-weight-bolder">
                            J’étudie déjà dans une autre faculté
                        </h3>
                    </div>
                    <div class="card-body d-flex flex-column">
                        <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 286.4 139.4" style="max-width: 40%;margin: auto;" xml:space="preserve">

<g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
	<path class="st0" d="M12,36.3H6.1v-5.8H12C12,30.6,12,36.3,12,36.3z M7.4,35.1h3.3v-3.3H7.4V35.1z"></path>
	<path class="st0" d="M30.8,36.3H25v-5.8h5.8V36.3z M26.2,35.1h3.3v-3.3h-3.3V35.1z"></path>
	<path class="st0" d="M11.8,14H6V8.2h5.8L11.8,14L11.8,14z M7.2,12.7h3.3V9.4H7.2V12.7z"></path>
	<path class="st0" d="M19.4,14h-5.8V8.2h5.8L19.4,14L19.4,14z M14.8,12.7h3.3V9.4h-3.3L14.8,12.7L14.8,12.7z"></path>
	<path class="st0" d="M11.8,21.4H6v-5.8h5.8L11.8,21.4L11.8,21.4z M7.2,20.2h3.3v-3.3H7.2V20.2z"></path>
	<path class="st0" d="M19.4,21.4h-5.8v-5.8h5.8L19.4,21.4L19.4,21.4z M14.8,20.2h3.3v-3.3h-3.3L14.8,20.2L14.8,20.2z"></path>
	<path class="st0" d="M11.8,28.9H6v-5.8h5.8L11.8,28.9L11.8,28.9z M7.2,27.6h3.3v-3.3H7.2V27.6z"></path>
	<path class="st0" d="M19.4,28.9h-5.8v-5.8h5.8L19.4,28.9L19.4,28.9z M14.8,27.6h3.3v-3.3h-3.3L14.8,27.6L14.8,27.6z"></path>
	<path class="st0" d="M30.7,21.4h-5.8v-5.8h5.8V21.4z M26.1,20.2h3.3v-3.3h-3.3L26.1,20.2L26.1,20.2z"></path>
	<path class="st0" d="M30.7,28.9h-5.8v-5.8h5.8V28.9z M26.1,27.6h3.3v-3.3h-3.3L26.1,27.6L26.1,27.6z"></path>
	<path class="st0" d="M34,41.9V12.7H22.7V5.5H2.6v36.4H0.3v1.2h13.3h5.8h16.8v-1.2H34z M32.8,14v27.9h-10V14H32.8z M14.9,41.9V31.8
		h3.3v10.1L14.9,41.9L14.9,41.9z M13.7,30.6v11.3H3.9V6.7h17.6v35.2h-2V30.6H13.7z"></path>
</g>
<g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
	<path class="st0" d="M75.7,35.9h-5.8v-5.8h5.8C75.7,30.1,75.7,35.9,75.7,35.9z M71.1,34.6h3.3v-3.3h-3.3V34.6z"></path>
	<path class="st0" d="M94.6,35.9h-5.8v-5.8h5.8V35.9z M90,34.6h3.3v-3.3H90V34.6z"></path>
	<path class="st0" d="M75.6,13.5h-5.8V7.7h5.8L75.6,13.5L75.6,13.5z M71,12.2h3.3V9H71V12.2z"></path>
	<path class="st0" d="M83.1,13.5h-5.8V7.7h5.8L83.1,13.5L83.1,13.5z M78.5,12.2h3.3V9h-3.3V12.2L78.5,12.2z"></path>
	<path class="st0" d="M75.6,20.9h-5.8v-5.8h5.8L75.6,20.9L75.6,20.9z M71,19.7h3.3v-3.3H71V19.7z"></path>
	<path class="st0" d="M83.1,20.9h-5.8v-5.8h5.8L83.1,20.9L83.1,20.9z M78.5,19.7h3.3v-3.3h-3.3V19.7L78.5,19.7z"></path>
	<path class="st0" d="M75.6,28.4h-5.8v-5.8h5.8L75.6,28.4L75.6,28.4z M71,27.2h3.3v-3.3H71V27.2z"></path>
	<path class="st0" d="M83.1,28.4h-5.8v-5.8h5.8L83.1,28.4L83.1,28.4z M78.5,27.2h3.3v-3.3h-3.3V27.2L78.5,27.2z"></path>
	<path class="st0" d="M94.4,20.9h-5.8v-5.8h5.8V20.9z M89.8,19.7h3.3v-3.3h-3.3V19.7L89.8,19.7z"></path>
	<path class="st0" d="M94.4,28.4h-5.8v-5.8h5.8V28.4z M89.8,27.2h3.3v-3.3h-3.3V27.2L89.8,27.2z"></path>
	<path class="st0" d="M97.8,41.4V12.2H86.5V5H66.4v36.4h-2.3v1.2h13.3h5.8h16.8v-1.2H97.8z M96.5,13.5v27.9h-10V13.5H96.5z
		 M78.7,41.4V31.3H82v10.1L78.7,41.4L78.7,41.4z M77.4,30.1v11.3h-9.8V6.2h17.6v35.2h-2V30.1H77.4z"></path>
</g>
<g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
	<path class="st0" d="M38.6,23.6C38.6,23.6,38.6,23.6,38.6,23.6C38.6,23.7,38.6,23.7,38.6,23.6c0,0.2,0,0.2,0,0.3c0,0,0,0,0,0
		c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1,0.1,0.1l4.7,4.7c0.1,0.1,0.2,0.2,0.4,0.2s0.3-0.1,0.4-0.2c0.2-0.2,0.2-0.5,0-0.7
		l-3.9-3.9h21.1c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5H40.4l3.9-3.9c0.2-0.2,0.2-0.5,0-0.7c-0.2-0.2-0.5-0.2-0.7,0l-4.7,4.7
		C38.7,23.5,38.7,23.5,38.6,23.6C38.7,23.5,38.7,23.5,38.6,23.6C38.6,23.6,38.6,23.6,38.6,23.6z"></path>
	<path class="st0" d="M61.9,33.9C62,33.9,62,33.8,61.9,33.9C62,33.8,62,33.8,61.9,33.9c0-0.1,0-0.2,0-0.2c0,0,0-0.1,0-0.1
		c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1l-4.7-4.7c-0.2-0.2-0.5-0.2-0.7,0c-0.2,0.2-0.2,0.5,0,0.7
		l3.9,3.9H39.1c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5h21.1L56.4,38c-0.2,0.2-0.2,0.5,0,0.7c0.1,0.1,0.2,0.2,0.4,0.2
		s0.3-0.1,0.4-0.2l4.7-4.7C61.9,34,61.9,34,61.9,33.9C61.9,34,61.9,33.9,61.9,33.9C61.9,33.9,61.9,33.9,61.9,33.9z"></path>
</g>
</svg>
			<div class="pt-5">
                            <button
                               
                                @click="setSession($event, 'T')"
                                class="btn btn-lg btn-primary btn-shadow-hover font-weight-bolder w-100 py-3"
                                data-title="transfert"
                            >
                                Déposer un dossier de transfert
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <b-modal
                ref="modalSession"
                no-close-on-backdrop
                no-close-on-esc
                hide-header-close
                v-bind:title="'Veuillez choisir la session de démarrage souhaitée pour votre dossier de '+modal.title"
            >
                <template #default>
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="form-group">
                                
                                <div class="radio-inl">
                                    <label
                                        v-for="(input, index) in sessions"
                                        class="radio radio-outline radio-primary radio-lg pt-4 pb-4"
                                        :key="index"
                                    >
                                        <input
                                            type="radio"
                                            name="session"
                                            v-model="form.session"
                                            v-bind:value="
                                                input.year + '+' + input.month
                                            "
                                        />
                                        <span></span>
                                        <div class="pl-2">{{ input.month + " " + input.year }}</div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template #modal-footer>
                    <div class="w-100">
                        <button
                            class="btn btn-secondary"
                            @click="$refs['modalSession'].hide()"
                        >
                            Annuler
                        </button>
                        <button
                            class="btn btn-primary float-right"
                            @click="goNext"
                        >
                            Je valide mon choix
                        </button>
                    </div>
                </template>
            </b-modal>
        </div>
    </div>
</template>
<script>
import Layout from "@/view/layout/Layout.vue";
export default {
    metaInfo: { title: "Tableau de bord" },
    layout: Layout,
    props: {
        sessions: Array
    },
    data() {
        return {
            form: {
                session: "",
                register: ""
            },
            modal: {
                title: ""
            }
        };
    },
    methods: {
        setSession(event, val) {
            this.modal.title = event.target.getAttribute("data-title");
            this.form.register = val;
            this.$refs["modalSession"].show();
        },
        goNext() {
            this.$inertia.visit("inscription/apply", {
                method: "POST",
                data: this.form,
                onError: errors => {
                    Swal.fire({
                        title: "",
                        html:
                            "Une erreur est survenue pendant votre inscription !<br>Veuillez réessayer",
                        icon: "error",
                        confirmButtonClass: "btn btn-secondary",
                        heightAuto: false
                    });
                }
            });
        }
    }
};
</script>
