<template>
    <div
        class="subheader py-2 py-lg-4 subheader-solid"
        v-bind:class="subheaderClasses"
        id="kt_subheader"
    >
        <div
            class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
            v-bind:class="{
                'container-fluid': widthFluid,
                container: !widthFluid
            }"
        >
            <div class="d-flex align-items-center flex-wrap mr-2">
                <h6 class="text-dark font-weight-bold mt-2 mb-2 mr-5">{{ Ndossier }}</h6>
                <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"></div>
                <ul
                    class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2"
                >
                    <li class="breadcrumb-item font-weight-bolder">{{ dossier }}</li>
                </ul>
            </div>
            <div class="d-flex align-items-center">
                <button
                    class="btn btn-secondary font-weight-bold btn-sm mr-2"
                    @click="$inertia.get('/admin/' + register)"
                >
                    <span class="svg-icon svg-icon-default svg-icon-1x">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                        >
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <polygon points="0 0 24 0 24 24 0 24" />
                                <path
                                    d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                    transform="translate(12.000003, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-12.000003, -11.999999) "
                                />
                            </g>
                        </svg>
                    </span>Retour à la liste
                </button>
                <button
                    class="btn btn-primary font-weight-bold btn-sm"
                    @click="$root.$refs.AdminInscriptionDetails.setProcessingSteps"
                >
                    Modifier l'étape de traitement
                </button>
                {{   }}
                <b-dropdown
                    size="sm"
                    variant="link"
                    no-caret
                    right
                    no-flip
                    text="Décision"
                    v-b-tooltip.hover="'Décision sur dossier'"
                    v-if="(currentUser.profile==='administrateur') || (currentUser.decisions.length > 0 && currentUser.profile==='utilisateur' && d_show)"
                >
                    <template v-slot:button-content>
                        <a
                            href="javascript:;"
                            class="btn btn-primary btn-sm"
                            data-toggle="dropdown"
                        >
                            <span class="navi-text">Décision</span>
                            <span class="navi-icon">
                                <i class="flaticon-more-1"></i>
                            </span>
                        </a>
                    </template>
                    <div class="navi navi-hover min-w-md-300px">
                        <template v-for="(item, key) in decisions">
                            <b-dropdown-text tag="div" class="navi-item" text-class="p-0"
                            v-if="(currentUser.decisions.includes(item.id) && currentUser.profile==='utilisateur') || currentUser.profile==='administrateur'"
                            >
                                <a
                                    href="javascript:;"
                                    @click="$root.$refs.AdminInscriptionDetails.MakeDecision(item.id)"
                                    class="navi-link"
                                >
                                    <span class="navi-icon">
                                        <i class="flaticon-folder" :class="`text-${item.class.split('-')[2]}`"></i>
                                    </span>
                                    <span class="navi-text" :class="`text-${item.class.split('-')[2]}`">
                                        {{ item.description }}
                                    </span>
                                </a>
                            </b-dropdown-text>
                        </template>

                        <!--<b-dropdown-text tag="div" class="navi-item" 
                            v-if="(currentUser.decisions.includes(5) && currentUser.profile==='utilisateur') || currentUser.profile==='administrateur'">
                            <a
                                href="javascript:;"
                                @click="$root.$refs.AdminInscriptionDetails.MakeDecision(5)"
                                class="navi-link"
                            >
                                <span class="navi-icon">
                                    <i class="flaticon2-checkmark text-success"></i>
                                </span>
                                <span class="navi-text">Dossier validé</span>
                            </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item" 
                            v-if="(currentUser.decisions.includes(4) && currentUser.profile==='utilisateur') || currentUser.profile==='administrateur'">
                            <a
                                href="javascript:;"
                                @click="$root.$refs.AdminInscriptionDetails.MakeDecision(4)"
                                class="navi-link"
                            >
                                <span class="navi-icon">
                                    <i class="flaticon-warning-sign text-warning"></i>
                                </span>
                                <span class="navi-text">Dossier incomplet</span>
                            </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item" 
                            v-if="(currentUser.decisions.includes(6) && currentUser.profile==='utilisateur') || currentUser.profile==='administrateur'">
                            <a
                                href="javascript:;"
                                @click="$root.$refs.AdminInscriptionDetails.MakeDecision(6)"
                                class="navi-link"
                            >
                                <span class="navi-icon">
                                    <i class="flaticon-circle text-danger"></i>
                                </span>
                                <span class="navi-text">Dossier refusé</span>
                            </a>
                        </b-dropdown-text>-->
                    </div>
                </b-dropdown>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
.custom-v-dropdown {
    &.dropdown-toggle {
        padding: 0;
        &:hover {
            text-decoration: none;
        }

        &.dropdown-toggle-no-caret {
            &:after {
                content: none;
            }
        }
    }

    &.dropdown-menu {
        margin: 0;
        padding: 0;
        outline: none;
        .b-dropdown-text {
            padding: 0;
        }
    }
}
</style>
<script>
import { mapGetters } from "vuex";

export default {
    name: "KTSubheader",
    props: {
        breadcrumbs: Array,
        title: String
    },
    data() {
        return {
            Ndossier: "",
            dossier: "",
            register: "",
            statusList: "",
            decisions: {},
            d_show: true
        };
    },
    created() {
        this.$root.$refs.AdminInscriptionSubHeader = this;
    },
    computed: {
        ...mapGetters(["layoutConfig"]),
        ...mapGetters(["currentUser"]),

        /**
         * Check if subheader width is fluid
         */
        widthFluid() {
            return this.layoutConfig("subheader.width") === "fluid";
        },

        subheaderClasses() {
            const classes = [];
            const style = this.layoutConfig("subheader.style");
            if (style) {
                classes.push(style);

                if (style === "solid") {
                    classes.push("bg-white");
                }

                if (this.layoutConfig("subheader.fixed")) {
                    classes.push("border-top");
                }
            }
            return classes.join(" ");
        }
    },
    mounted(){
        
        /*this.axios.get('/admin/user').then((response) =>{
            this.currentUser=response.data.user
        })*/
  },
};
</script>
