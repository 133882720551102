<template>
    <div class="d-flex flex-column flex-lg-row flex-column-fluid bg-white">
        <Aside></Aside>
        <div class="d-flex flex-column flex-row-fluid position-relative p-7 pl-20 pr-20 overflow-hidden">
            <div class="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
                  

                <form class="form" novalidate="novalidate" id="signup_form">
                      <div class="logo-holder text-center mb-20 logo-mobile">
                    <img src="/media/logos/ecole-logo.png" alt="école de medecine saint christopher iba mar diop" class="max-w-200px "> 
                    </div>
                    
                    <div class="landing-intro pb-13 pt-lg-0 pt-5 ">
                        <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">Inscrivez-vous</h3>
                        <p
                            class=" font-size-h4 "
                        >Remplissez le formulaire en bas et cliquez sur inscription pour initier votre candidature et avoir accès au portail des admissions. </p>
                    </div>
                    <div class="form-group">
                        <input
                            class="form-control form-control-solid h-auto py-4 px-4  font-size-h7"
                            type="text"
                            placeholder="Nom"
                            name="nom"
                            v-model="form.nom"
                            autocomplete="off"
                        >
                    </div>
                    <div class="form-group">
                        <input
                            class="form-control form-control-solid h-auto py-4 px-4  font-size-h7"
                            type="text"
                            placeholder="Prénom"
                            name="prenom"
                            v-model="form.prenom"
                            autocomplete="off"
                        >
                    </div>
                    <div class="form-group">
                        <input
                            class="form-control form-control-solid h-auto py-4 px-4  font-size-h7"
                            type="email"
                            placeholder="Email"
                            name="email"
                            v-model="form.email"
                            autocomplete="off"
                        >
                    </div>
                    <div class="form-group">
                        <vue-tel-input
                            class="form-control form-control-solid h-auto py-4 px-4  font-size-h7"
                            :inputOptions="optionsPhone"
                            v-bind="bindProps"
                            name="phone"
                            v-model="form.phone"
                            @validate="validePhone"
                            :autoDefaultCountry="true"
                        ></vue-tel-input>
                    </div>
                    <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                        <button
                            ref="signup_submit"
                            :disabled = "btn_enable"
                            class="btn btn-primary cta-btn font-weight-bolder font-size-h7 px-8 py-4 my-3 btn-lg  btn-block"
                        >
                            <span class="title">Inscription</span><br>Soumettre sa candidature
                        </button>
                        <div class="divider"><hr/><span>OU</span></div>
                        <button
                            v-on:click="signin" 
                            class="btn cta-btn btn-outline-primary btn-block btn-lg mb-10"
                            :disabled = "btn_enable"
                        >
                            <span class="title">Portail des admissions</span><br/>Compléter ou vérifier le statut de sa candidature
                        </button>

                       
<br/>
<hr/>
<span>En vous inscrivant, vous déclarez avoir pris connaissance de la politique de confidentialité de l’école de médecine Saint Christopher Iba Mar Diop et que vous acceptez  d'être contacté ultérieurement par l’école de médecine Saint Christopher Iba Mar Diop
</span>

                    </div>
                </form>


                
            </div>
        </div>

    </div>
</template>
<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";
import Swal from "sweetalert2";
import Aside from "@/view/pages/Web/Auth/AsideSignup"

export default {
    data() {
        return {
            fv:null,
            optionsPhone: { placeholder: "Numéro de téléphone" },
            bindProps:{ mode: 'international'},
            validphone:'',
            form: {
                nom:"",
                prenom:"",
                email: "",
                phone: "",
            },
            btn_enable: false
        }
    },
    components: {
        Aside
    },     
    mounted() {
        const signup_form = KTUtil.getById("signup_form");
        const submitButton = this.$refs["signup_submit"];

        this.fv = formValidation(signup_form, {
            fields: {
                nom: {
                    validators: {
                        notEmpty: {
                            message: "Nom obligatoire",
                        },
                    },
                },
                prenom: {
                    validators: {
                        notEmpty: {
                            message: "Prénom obligatoire",
                        },
                    },
                },
                email: {
                    validators: {
                        notEmpty: {
                            message: "E-amil obligatoire",
                        },
                        emailAddress:{
                            message: "Adresse e-mail invalide",
                        }
                    },
                },
                phone: {
                    selector: '[placeholder="Numéro de téléphone"]',
                    validators: {
                        notEmpty: {
                            message: "Téléphone obligatoire",
                        },
                        stringLength: {
                            min: 2,
                            message: "Téléphone portable invalide",
                        }
                    },
                },
            },
            plugins: {
                trigger: new Trigger(),
                submitButton: new SubmitButton(),
                bootstrap: new Bootstrap(),
            },
        });
        submitButton.addEventListener('click', () => {
            this.fv.validate().then((status) => {
                const parentPhone=document.querySelector('[name="phone"]').parentElement
                if (status === 'Valid') {
                    if(!this.validphone){
                        /*parentPhone.getElementsByClassName("vti__input")[0].classList.remove("is-valid");
                        parentPhone.getElementsByClassName("vti__input")[0].classList.add("is-invalid");
                        parentPhone.getElementsByClassName("fv-plugins-message-container")[0].innerHTML='<div data-field="contact_phone" data-validator="notEmpty" class="fv-help-block">Numéro de téléphone ivalide</div>'*/
                        this.fv.updateFieldStatus('phone','Invalid')
                        return
                    }
                    else{
                        /*parentPhone.getElementsByClassName("vti__input")[0].classList.remove("is-invalid");
                        parentPhone.getElementsByClassName("fv-plugins-message-container")[0].innerHTML=""*/
                        this.fv.updateFieldStatus('phone','Valid')
                    }
                    this.sendData();
                }
            })
        })
    },
    methods: {
        signin(){
            this.$inertia.get('login')
        },
        sendData(){
            var nom = this.form.nom;
            var prenom = this.form.prenom;
            var email = this.form.email;
            var phone = this.form.phone;

            const submitButton = this.$refs["signup_submit"];
            submitButton.classList.add("spinner", "spinner-light", "spinner-right");
            this.btn_enable = true
            
            this.axios
                .post('admission/register', { nom, prenom, email, phone })
                .then((response) => {
                    if(response.data.validation){
                        this.$inertia.get('confirm')
                    }else{
                        Swal.fire({
                            title: "",
                            html: response.data.message,
                            icon: "error",
                            confirmButtonClass: "btn btn-danger",
                            heightAuto: false,
                        });
                        if (typeof response.data.validation.list !== "undefined") {
                            response.data.validation.list.forEach(function(item, index){
                                this.fv.updateFieldStatus(item,'Invalid')
                            })
                        }
                        this.btn_enable = false
                        submitButton.classList.remove("spinner","spinner-light","spinner-right");
                    }
                })
                .catch((error) => {
                    this.btn_enable = false
                    submitButton.classList.remove("spinner","spinner-light","spinner-right");
                    Swal.fire({
                        title: "",
                        html: error.response.data.message,
                        icon: "error",
                        confirmButtonClass: "btn btn-danger",
                        heightAuto: false,
                    });
                })
        },
        validePhone(phoneData){
            this.validphone=phoneData.valid
            this.form.phone=phoneData.number
        },
    }
}
</script>
