<template>
    <div class="topbar-item">
        <b-dropdown
            size="sm"
            variant="link"
            toggle-class="btn btn-icon w-auto d-flex align-items-center btn-lg px-2 border-0"
            no-caret
        >
            <template v-slot:button-content>
                <span
                    class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
                >
                    {{ currentUser.nom + " " + currentUser.prenom }}
                </span>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                >
                    <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                    >
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path
                            d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                            fill="#FFFFFF"
                            fill-rule="nonzero"
                            opacity="0.3"
                        />
                        <path
                            d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                            fill="#FFFFFF"
                            fill-rule="nonzero"
                        />
                    </g>
                </svg>
            </template>
            <b-dropdown-text tag="div" class="min-w-md-275px">
                <div class="navi navi-spacer-x-0 pt-5 ">
                    <a
                        href="javascript:;"
                        @click="$inertia.get('/admin/profile/change-password')"
                        class="navi-item px-8"
                    >
                        <div class="navi-link">
                            <div class="navi-icon mr-2">
                                <i class="flaticon-lock text-success"></i>
                            </div>
                            <div class="navi-text">
                                <div class="font-weight-bold">
                                    Changer mon mot de passe
                                </div>
                            </div>
                        </div>
                    </a>

                    <div class="navi-separator mt-3"></div>
                    <div class="navi-footer  px-8 py-5">
                        <a @click="onLogout" href="javascript:;" class="btn btn-light-primary font-weight-bold">
                            Déconnexion
                        </a>
                    </div>
                </div>
            </b-dropdown-text>
        </b-dropdown>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/core/services/store/auth.module";
export default {
    computed: {
        ...mapGetters(["currentUser"])
    },
    methods: {
        onLogout() {
            let role = this.currentUser.role;
            this.$store.dispatch(LOGOUT)
            .then(() => {
                window.location.replace(`/${role}/logout`)
            })
        }
    }
};
</script>
