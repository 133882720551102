<template>
    <div class="d-flex flex-column flex-lg-row flex-column-fluid bg-white">
        <Aside></Aside>
        <div class="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
            <div class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
                <span class="font-weight-bold text-dark-50">Vous avez déja un compte ?</span>
                <a href="javascript:;" v-on:click="signin" class="btn btn-outline-primary btn-sm">Connexion!</a>
            </div>
            <div class="d-flex flex-column-fluid flex-center mt-0 mt-lg-0">
                <div class="d-main-content d-flex flex-column flex-root">
                    <div class="success-animation">
                        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                    </div>
                    <div class="success-h1 text-h1">Votre mot de passe a été réinitialiser avec succès</div>
                    <div class="success-sub text-sub-1">
                        Un email de confirmation vous a été envoyé à l'adresse mail indiquée avec un nouveau mot de passe pour accéder au portail d’admissions et compléter votre candidature.
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import Aside from "@/view/pages/Web/Auth/Aside"

export default {
    data() {
        return {
        }
    },
    components: {
        Aside
    },
    methods: {
        signin(){
            this.$inertia.get('login')
        },
    }
}
</script>
<style>
.success-animation { margin:10rem auto 3rem;}
.checkmark {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #4bb71b;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #4bb71b;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    position:relative;
    top: 5px;
    right: 5px;
    margin: 0 auto;
}
.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #4bb71b;
    fill: #fff;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}
@keyframes scale {
    0%, 100% {
        transform: none;
    }
    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}
@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #4bb71b;
    }
}
.d-main-content{
    font-family: 'Roboto', sans-serif;
    color: rgb(20, 20, 20);
    text-align: center
}
.text-h1{
    font-size: 3rem !important;
    font-weight: 900 !important;
    font-family: 'Roboto', sans-serif;
}
.text-sub-1{
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
    font-weight: 900;
    width:80%;
    margin:0rem auto 3rem;
}
.text-p-info{
    font-size: 1.3rem !important;
    font-family: 'Roboto', sans-serif;
    padding: 1.2rem 0.8rem 0.8rem;
    color: rgb(116, 116, 116)
}
.big-link {
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
    font-weight: 900;
    color: rgb(11, 85, 147,0.9);
}
</style>
